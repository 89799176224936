import React from "react";
import styled from "styled-components";
import "../App.css";

import SubHeader from "../components/subHeader";
import Socials from "../components/socials";
import Contact from "../components/contact";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Caps = styled.div`
  width: 100%;
  font-size: 3em;
  text-align: center;
  margin: 5px 0;
  font-family: "Montserrat", Montserrat , sans-serif;
  text-transform: uppercase;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
  @media only screen and (min-width: 2500px) {
  font-size: 8em;
  }
`;

const CapsBody = styled.div`
  width: 90vw;
  font-size: 1.2em;
  text-align: center;
  margin: 5px 10px;
  font-family: "Montserrat", Montserrat , sans-serif;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
    max-width: 900px;
  }
  @media only screen and (min-width: 1600px) {
  max-width: 1000px;
  }
  @media only screen and (min-width: 2500px) {
  font-size: 3em;
  }
`;

const Liveevents = () => {
  return (
    <Container>
      <SubHeader />
      <Caps>Live events</Caps>
      <CapsBody>Live illustrations are a unique keepsake that your guests will treasure for years to come. <br/>
        Fill in the details below to receive a quote for Crystal to attend your special event.
      </CapsBody>
      <br/>
      <FormContainer>
        <Contact />
      </FormContainer>

      <Socials />
    </Container>
  );
};

export default Liveevents;