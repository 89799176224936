import React, { useEffect } from "react";
import "./App.css";
import styled from "styled-components";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import About from "./pages/about";
import Clientwork from "./pages/clientwork";
import Gallery from "./pages/gallery";
import Liveevents from "./pages/liveevents";
import Index from "./pages/index";
import Shop from "./pages/shop";

const Page = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
`;

function App() {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <Page>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/clientwork" element={<Clientwork />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/liveevents" element={<Liveevents />} />
          <Route exact path="/shop" element={<Shop />} />
        </Routes>
      </BrowserRouter>
    </Page>
  );
}

export default App;
