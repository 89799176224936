import React from "react";
import styled from "styled-components";
import "../App.css";

import ProgressiveImage from "../components/progressiveImage";

import SubHeader from "../components/subHeader";
import Socials from "../components/socials";

import CTate from "../images/branding/ctate.jpg"
import CTateMini from "../images/branding/ctate_mini.jpg"

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Caps = styled.div`
  width: 100%;
  font-size: 3em;
  text-align: center;
  margin: 5px 0;
  font-family: "Montserrat", Montserrat , sans-serif;
  text-transform: uppercase;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
  @media only screen and (min-width: 2500px) {
  font-size: 8em;
  }
`;

const CapsBody = styled.div`
  width: 90vw;
  font-size: 1.2em;
  text-align: center;
  margin: 5px 10px;
  font-family: "Montserrat", Montserrat , sans-serif;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  max-width: 1000px;
  }
  @media only screen and (min-width: 2500px) {
  font-size: 3em;
  max-width: 1500px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;  
  width: 100vw;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  max-width: 800px;
  }
  @media only screen and (min-width: 1600px) {
  max-width: 1200px;
  }
  @media only screen and (min-width: 2500px) {
  max-width: 2000px;
  }
`

const About = () => {
  return (
    <Container>
      <SubHeader />
      <Caps>About</Caps>
      <ImageContainer>
        <ProgressiveImage key={0}
          src={CTate}
          placeholderSrc={CTateMini}
          alt="A collage of images of art made by the artist" />
      </ImageContainer>
      <CapsBody>
        Crystal Tate is a passionate local artist residing on Gumbaynggirr land.
        <br /><br />
        Life is a million moments, ever moving, ever changing.
        Every now and then, we stop and ‘collect’ one of these moments that feels like it matters; it might light us up, make us pause, help us slow down, or even cause us to laugh.
        <br /><br />
        Tate illustrates these moments by creating vibrant landscapes and sweet portraits to brighten your walls. Each artwork is lovingly hand drawn in her unique and quirky style to connect us to the places and people we love.
        Self taught, ever-curious and always growing- Tate currently produces most of her print art by hand in Procreate on her Apple iPad.
        When she’s playing with paper or canvas, she loves to use a mix of acrylic, gouache, watercolours, pencils, felt tip markers and pens.
        <br /><br />
        Tate has enjoyed working with a number of individuals and businesses to create memorable and unique art. These include; Bellingen Shire Council, Camp Creative and Hot Myrtle Kitchen.
        <br /><br />
        Want a custom artwork or something you’ve already seen? Head to her shop!
        <br /><br />
        If you have an idea that doesn’t quite fit what’s already listed, feel free to connect with her directly through the email tab on this website.
        <br /><br />
        Tate has recently started offering live portraits for gatherings of up to 10 people, or you can catch her in the wild at most Urunga Riverside Markets.
        <br /><br />
        Latest exhibition news and projects will be shared on Instagram.
        To keep up to date with what she is doing, make sure you follow along.

      </CapsBody>
      <Socials />
    </Container>
  );
};

export default About;