import React from "react";
import styled from "styled-components";
import Logo from "../images/branding/logo.jpg";

const Image = styled.img`
  height: 100px;
  max-width: 960px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 480px) {
  height: 150px;
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
    height: 200px;
  }
`;

const Container = styled.div`
  display: block;

  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 480px) {
    min-height: 150px;
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Header = () => {
  return (
    <Container>
      <a href="/">
          <Image src={Logo} alt="c t illustrations banner" />
      </a>
    </Container>
  );
};

export default Header;
