import * as React from "react";
import styled from "styled-components";
import { Outlet, Link } from "react-router-dom";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  width: 90%;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  max-width: 900px;
  }
  @media only screen and (min-width: 1600px) {
  }
  @media only screen and (min-width: 2500px) {
  max-width: 1500px;
  }
`;

const Caps = styled.div`
  width: 95%;
  font-size: 1.3em;
  text-align: center;
  margin: 5px 20px;
  font-family: "Montserrat", Montserrat , sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  max-width: 375px;
  @media only screen and (min-width: 480px) {
  max-width: 475px;
  }
  @media only screen and (min-width: 768px) {
  max-width: 760px;
  }
  @media only screen and (min-width: 1024px) {
  max-width: 900px;
  }
  @media only screen and (min-width: 1600px) {
  max-width: 1200px;
  }
  @media only screen and (min-width: 2500px) {
  font-size: 2em;
  max-width: 1500px;
  }
`;

const Nav = () => {
  return (
    <Container>      
        <Link
          to="/about"
          spy={true}
          smooth={true}
          onClick={() => this.closeMenu()}
        >
          <Caps>ABOUT</Caps>
        </Link>
        <Link
          to="/gallery"
          spy={true}
          smooth={true}
          onClick={() => this.closeMenu()}
        >
          <Caps>GALLERY</Caps>
        </Link>
        <Link
          to="/clientwork"
          spy={true}
          smooth={true}
          onClick={() => this.closeMenu()}
        >
          <Caps>CLIENT WORK</Caps>
        </Link>
        <Link
          to="/liveevents"
          spy={true}
          smooth={true}
          onClick={() => this.closeMenu()}
        >
          <Caps>LIVE EVENTS</Caps>
        </Link>
        <Link
          to="/shop"
          spy={true}
          smooth={true}
          onClick={() => this.closeMenu()}
        >
          <Caps>SHOP</Caps>
        </Link>
    </Container>
  );
};

export default Nav;
