import styled from "styled-components";

import BirdL from "../images/branding/birdL.jpg";
import BirdR from "../images/branding/birdR.jpg";

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  width: 70%;
  }
  @media only screen and (min-width: 2500px) {
  height: 200px;
  }
`;

const LeftImage = styled.img`
  height: 100%;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
  @media only screen and (min-width: 2500px) {    
  }
`;
const RightImage = styled.img`
  height: 100%;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
  @media only screen and (min-width: 2500px) {    
  }
`;

const Cursive = styled.div`
  width: 50%;
  font-size: 4em;
  text-align: center;
  margin: 5px 0;
  max-width: 900px;
  font-family: Sacramento;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  font-size: 7em;
  width: 40%;
  }
  @media only screen and (min-width: 1600px) {
  }
  @media only screen and (min-width: 2500px) {
  font-size: 10em;
  width: 50%;
  }
`;

const Banner = () => {
  return (
    <Container>
      <LeftImage src={BirdR} alt="silhouette of stylised bird" />
      <Cursive>you love</Cursive>
      <RightImage src={BirdL} alt="silhouette of stylised bird" />
    </Container>
  );
};

export default Banner;
