import React, { useEffect } from "react";
import "../App.css";
import styled from "styled-components";

import ProgressiveImage from "../components/progressiveImage";

import Header from "../components/header";
import Nav from "../components/nav";
import Socials from "../components/socials";
import Burger from "../components/multilevel-dropdown-menu/burger";
import Banner from "../components/banner";

import MasonryImage from "../images/branding/masonry.jpg";
import MasonryImageMini from "../images/branding/masonry_mini.jpg";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
    padding: 0 50px;
  }
  @media only screen and (min-width: 2500px) {    
  }
`;

const Page = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100%;
  max-width: 375px;
  @media only screen and (min-width: 480px) {
  max-width: 475px;
  }
  @media only screen and (min-width: 768px) {
  max-width: 760px;
  }
  @media only screen and (min-width: 1024px) {
  max-width: 900px;
  }
  @media only screen and (min-width: 1600px) {
  max-width: 1200px;  
  padding: 0 50px;
  }
  @media only screen and (min-width: 2500px) {
  max-width: 2000px;
  }
`;

const Masonry = styled.img`
  width: 100%;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
    width: 90%;
    padding: 20px;
    max-width: 1200px;
  }
`;
const Acknowledgement = styled.div`
  width: 95%;
  font-size: 0.8em;
  text-align: center;
  margin: 5px 0;
  max-width: 900px;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
  @media only screen and (min-width: 2500px) {  
  font-size: 2em;
  max-width: 1500px;  
  }
`;

const Caps = styled.div`
  width: 95%;
  font-size: 1.8em;
  text-align: center;
  margin: 5px 0;
  font-family: "Montserrat", Montserrat , sans-serif;
  text-transform: uppercase;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  font-size: 2.5em;
  }
  @media only screen and (min-width: 1600px) {
  font-size: 3em;
  width: 70%;
  }
  @media only screen and (min-width: 2500px) {
  font-size: 5em;
  }
`;

const Index = () => {
  return (
    <Container>
      <Page>
        <div id="top" />
        <Header />
        <ProgressiveImage key={0}
            src={MasonryImage}
            placeholderSrc={MasonryImageMini}
            alt="A collage of images of art made by the artist" />
        {window.innerWidth > 680 ? (
          <Nav />
        ) : (
          <Burger />
        )}
        <Caps>connecting you to the people and places</Caps>
        <Banner />
        <br/>
        <br/>
        <Socials />
        <Acknowledgement>
          We acknowledge and pay our respects to the traditional custodians of
          the lands and waters of the Gumbaynggirr, and all Aboriginal Elders,
          past, present, and emerging.
        </Acknowledgement>
      </Page>
    </Container>
  );
};

export default Index;
