import React, { useState } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";

// import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";

const Container = styled.div`
  display: block;

  margin-left: auto;
  margin-right: auto;
`;

const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  @media only screen and (min-width: 2500px) {
  font-size: 3em;
  }
`;

const Input = styled.input`
  width: 100px;
  display: block;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  margin-top: 15px;
  @media only screen and (min-width: 2500px) {
  width: 300px;
  font-size: 1em;
  }
`;

const TextArea = styled.textarea`
  width: 80%;
  display: block;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  margin-top: 15px;
  @media only screen and (min-width: 2500px) {
  width: 300px;
  font-size: 1em;
  }
`;


const Contact = () => {
  const [contact, setContact] = useState({
    name: "",
    reply_to: "",
    phone: "",
    message: "",
  });
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setContact((prev) => ({ ...prev, [name]: value }));
    e.preventDefault();
  };

  function sendForm() {
    console.log(contact);
    emailjs
      .send(
        "service_CTIllustrationss",
        "template_CTIllustrations",
        contact,
        "lC3qN2NqNa13VitI4"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
    resetContact();
    handleClose();
  }

  function resetContact() {
    setContact({ name: "", reply_to: "", phone: "", date: "", time: "", location: "", headcount: "", typed: false, message: "" });
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(sendForm)}>
        <input type="hidden" name="contact_number" />
        <Label>
          Name:
          <Input
            required
            type="text"
            name="name"
            id="name"
            placeholder="required"
            value={contact.name}
            onChange={handleChange}
          />
        </Label>
        <br />
        <Label>
          Email:
          <Input
            required
            type="email"
            name="reply_to"
            id="reply_to"
            placeholder="required"
            value={contact.reply_to}
            onChange={handleChange}
          />
        </Label>
        <br />
        <Label>
          Number:
          <Input
            type="tel"
            name="phone"
            id="phone"
            placeholder="optional"
            value={contact.phone}
            onChange={handleChange}
          />
        </Label>
        <br />
        <Label>
          Date and time of event:
          <Input
            required
            type="date"
            name="date"
            id="date"
            placeholder="required"
            value={contact.date}
            onChange={handleChange}
          />
          <Input
            required
            type="time"
            name="time"
            id="time"
            placeholder="required"
            value={contact.time}
            onChange={handleChange}
          />
        </Label>
        <br />
        <Label>
          Location of event:
          <Input
            required
            type="text"
            name="location"
            id="location"
            placeholder="required"
            value={contact.location}
            onChange={handleChange}
          />
        </Label>
        <br />
        <Label>
          Number of people:
          <Input
            required
            type="number"
            name="headcount"
            id="headcount"
            placeholder="required"
            value={contact.headcount}
            onChange={handleChange}
          />
        </Label>
        <br />
        <Label>
          Typed font under portrait required:
          <Input
            required
            type="checkbox"
            name="typed"
            id="typed"
            placeholder="required"
            value={contact.typed}
            onChange={handleChange}
          />
        </Label>
        <br />
        <Label>
          Additional info:
          <TextArea
            name="message"
            id="message"
            placeholder="optional"
            value={contact.message}
            onChange={handleChange}
            cols="50"
            rows="4"
          />
        </Label>
        <br />
        <Input type="submit" value="Send" />
      </Form>
    </Container>
  );
};

export default Contact;
