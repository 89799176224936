import React from "react";
import styled from "styled-components";
import "../App.css";

import SubHeader from "../components/subHeader";
import Socials from "../components/socials";
import ProgressiveImage from "../components/progressiveImage";
import ScrollTopButton from "../components/multilevel-dropdown-menu/scrollTopButton";

const images = require.context('../images/client', false);
const imageList = images.keys().map(image => images(image));
const images_mini = require.context('../images/client/mini', false);
const imageList_mini = images_mini.keys().map(image => images_mini(image));

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const GalleryContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;  
  width: 100vw;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  max-width: 800px;
  }
  @media only screen and (min-width: 1600px) {
  max-width: 1200px;
  }
  @media only screen and (min-width: 2500px) {
  max-width: 2000px;
  }
`

const Caps = styled.div`
  width: 100%;
  font-size: 3em;
  text-align: center;
  margin: 5px 0;
  font-family: "Montserrat", Montserrat , sans-serif;
  text-transform: uppercase;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
  @media only screen and (min-width: 2500px) {
  font-size: 8em;
  }
`;

const Clientwork = () => {
  return (
    <Container>
      <SubHeader/>
      <Caps>client work</Caps>
      <ScrollTopButton />
      <GalleryContainer>
        {imageList.map((image, index) => (
          <ProgressiveImage
            key={index}
            src={image}
            placeholderSrc={imageList_mini[index]}
            alt={`image-${index}`}
          />
        ))}
      </GalleryContainer>
      <Socials/>
    </Container>
  );
};

export default Clientwork;