import React from "react";
import styled from "styled-components";
import Logo from "../images/branding/logo.webp";

import Header from "../components/header";
import Nav from "../components/nav";
import Burger from "../components/multilevel-dropdown-menu/burger";

const Container = styled.div`
  display: block;

  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 480px) {
    min-height: 150px;
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const SubHeader = () => {
  return (
    <Container>
      <Header />
      {window.innerWidth > 680 ? (
        <Nav />
      ) : (
        <Burger />
      )}
    </Container>
  );
};

export default SubHeader;