import React from "react";
import styled from "styled-components";

import facebook from "../images/socials/fb_logo.png";
import insta from "../images/socials/insta_logo.png";
import email from "../images/socials/email_logo.png";
import pinterest from "../images/socials/pinterest_logo.png";

const Container = styled.div`
  display: block;

  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 999px;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  padding: 20px 0;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: centre;

  width: 100%;
  margin: 15px 0;
`;

const SocialLink = styled.a`
  margin: auto;
  display: flex;
  justify-content: center;
`;

const Icon = styled.img`
  width: 30px;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
  @media only screen and (min-width: 2500px) {
  width: 50px;
  }
`;

const Socials = () => {
  return (
    <Container>
      <FlexContainer>
        <SocialLink
          href="mailto: helloctillustrations@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={email} alt="email logo"></Icon>
        </SocialLink>
        <SocialLink
          href="https://www.instagram.com/crystaltateillustrations/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={insta} alt="instagram logo"></Icon>
        </SocialLink>
        <SocialLink
          href="https://www.facebook.com/C-T-Illustrations-101024289404002"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={facebook} alt="facebook logo"></Icon>
        </SocialLink>

        <SocialLink
          href="https://pin.it/lySdxBB"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={pinterest} alt="pintrest logo"></Icon>
        </SocialLink>
      </FlexContainer>
    </Container>
  );
};

export default Socials;
