import React from "react";
import styled from "styled-components";
import "../App.css";

import SubHeader from "../components/subHeader";
import Socials from "../components/socials";
import Etsy from "../images/socials/etsy.png";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Caps = styled.div`
  width: 100%;
  font-size: 3em;
  text-align: center;
  margin: 5px 0;
  font-family: "Montserrat", Montserrat , sans-serif;
  text-transform: uppercase;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const SocialLink = styled.a`
  margin: auto;
  display: flex;
  justify-content: center;
`;

const Icon = styled.img`
  width: 100%;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Shop = () => {
  return (
    <Container>
      <SubHeader/>
      <Caps>Shop</Caps>
      <SocialLink
          href="https://www.etsy.com/au/shop/CTIllustrationsShop?ref=shop-header-name&listing_id=1637327066&from_page=listing"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon src={Etsy} alt="Etsy logo"></Icon>
        </SocialLink>
      <Socials/>
    </Container>
  );
};

export default Shop;